import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://yxkxwciuwnbzqvgtxsqc.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl4a3h3Y2l1d25ienF2Z3R4c3FjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM3NTMzMTQsImV4cCI6MjAzOTMyOTMxNH0.kkwxzvvq-ntY8NE1zly5A3I155-ulHXRi-PItljJdzw';
const supabase = createClient(supabaseUrl, supabaseKey);

export const getProfile = async (searchId) => {
    const { data, error } = await supabase
            .from('user_profiles')
            .select()
            .eq('id', searchId)

    return data[0];
}